function shuffle(array: string[]) {
  for (let i = array.length - 1; i > 0; i--) {
    // Generate a random index lower than the current element
    const j = Math.floor(Math.random() * (i + 1));

    // Swap elements at indices i and j
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

export const FALLBACK_IMAGE = 'fallback.jpg';

function normalizeWordList({
  paths,
  imagePathToWordFn,
  imageUrlRoot,
}: {
  paths: string[];
  imagePathToWordFn?: (item: string) => string;
  imageUrlRoot: string;
}): WordListRecord[] {
  return shuffle(paths).map((path) => {
    const word = imagePathToWordFn ? imagePathToWordFn(path) : path;
    const fullPath = `${imageUrlRoot}/${path}`;
    return {
      word,
      path: fullPath,
    };
  });
}

function getImageUrlRoot(suffix?: string) {
  if (process.env.NODE_ENV === 'development') {
    return `http://images.wordwizard.smackesey.us${suffix}`;
  } else {
    return `http://images.wordwizard.smackesey.us${suffix}`;
  }
}

export type WordListRecord = {
  word: string;
  path: string;
};

const readingBearImagePathToWordFn = (path: string) => path.split('/').pop()!.replace('.jpg', '');

export const WORD_LISTS: Map<string, WordListRecord[]> = new Map([
  [
    'short-vowel-dalle',
    normalizeWordList({
      paths: [
        // short a
        'bag.png',
        'bat.png',
        'bin.png',
        'cab.png',
        'cub.png',
        'can.png',
        'cat.png',
        'fan.png',
        'ham.png',
        'hat.png',
        'jam.png',
        'lad.png',
        'man.png',
        'map.png',
        'mat.png',
        'pan.png',
        'rag.png',
        'rat.png',
        'van.png',

        // short e
        'bed.png',
        'hen.png',
        'jet.png',
        'keg.png',
        'leg.png',
        'net.png',
        'peg.png',
        'pen.png',
        'web.png',

        // short i
        'fin.png',
        'hit.png',
        'kid.png',
        'pig.png',
        'pin.png',
        'sit.png',
        'wig.png',

        // short u
        'bug.png',
        'bun.png',
        'bus.png',
        'cup.png',
        'hug.png',
        'hut.png',
        'jug.png',
        'mud.png',
        'mug.png',
        'nun.png',
        'nut.png',
        'run.png',
        'sun.png',
        'tub.png',
      ],
      imageUrlRoot: getImageUrlRoot('/short-vowel-dalle'),
      imagePathToWordFn: (path: string) => path.replace('.png', ''),
    }),
  ],
  [
    // short a
    // short e
    // short i
    // short o
    // short u
    'reading-bear-1',
    normalizeWordList({
      paths: [
        '1/bag.jpg',
        '1/bat.jpg',
        '1/dad.jpg',
        '1/fan.jpg',
        '1/gas.jpg',
        '1/had.jpg',
        '1/ham.jpg',
        '1/jam.jpg',
        '1/jazz.jpg',
        '1/lap.jpg',
        '1/mad.jpg',
        '1/man.jpg',
        '1/map.jpg',
        '1/mat.jpg',
        '1/nap.jpg',
        '1/pad.jpg',
        '1/pan.jpg',
        '1/pass.jpg',
        '1/pat.jpg',
        '1/rag.jpg',
        '1/rat.jpg',
        '1/sad.jpg',
        '1/sam.jpg',
        '1/tan.jpg',
        '1/tap.jpg',
        '1/van.jpg',
        '1/wag.jpg',
        '3/Ed.jpg',
        '3/Ted.jpg',
        '3/bed.jpg',
        '3/beg.jpg',
        '3/bell.jpg',
        '3/ben.jpg',
        '3/ebb.jpg',
        '3/egg.jpg',
        '3/get.jpg',
        '3/hen.jpg',
        '3/jet.jpg',
        '3/leg.jpg',
        '3/less.jpg',
        '3/let.jpg',
        '3/men.jpg',
        '3/mess.jpg',
        '3/net.jpg',
        '3/pen.jpg',
        '3/pet.jpg',
        '3/red.jpg',
        '3/set.jpg',
        '3/tell.jpg',
        '3/ten.jpg',
        '3/web.jpg',
        '3/well.jpg',
        '3/wet.jpg',
        '3/yell.jpg',
        '3/yes.jpg',
        '4/Jill.jpg',
        '4/Tim.jpg',
        '4/bib.jpg',
        '4/big.jpg',
        '4/bill.jpg',
        '4/bit.jpg',
        '4/did.jpg',
        '4/dig.jpg',
        '4/hill.jpg',
        '4/him.jpg',
        '4/hit.jpg',
        '4/ill.jpg',
        '4/in.jpg',
        '4/lid.jpg',
        '4/mill.jpg',
        '4/pig.jpg',
        '4/pin.jpg',
        '4/rib.jpg',
        '4/rip.jpg',
        '4/sis.jpg',
        '4/sit.jpg',
        '4/tip.jpg',
        '4/wig.jpg',
        '4/will.jpg',
        '4/zip.jpg',
        '5/Don.jpg',
        '5/God.jpg',
        '5/Tom.jpg',
        '5/bob.jpg',
        '5/boss.jpg',
        '5/dog.jpg',
        '5/doll.jpg',
        '5/dot.jpg',
        '5/fog.jpg',
        '5/got.jpg',
        '5/hog.jpg',
        '5/hop.jpg',
        '5/hot.jpg',
        '5/log.jpg',
        '5/lot.jpg',
        '5/mop.jpg',
        '5/moss.jpg',
        '5/nod.jpg',
        '5/not.jpg',
        '5/pop.jpg',
        '5/pot.jpg',
        '5/rob.jpg',
        '5/sob.jpg',
        '5/top.jpg',
        '5/toss.jpg',
        '6/bud.jpg',
        '6/bug.jpg',
        '6/bun.jpg',
        '6/bus.jpg',
        '6/but.jpg',
        '6/buzz.jpg',
        '6/dull.jpg',
        '6/full.jpg',
        '6/fun.jpg',
        '6/fuss.jpg',
        '6/fuzz.jpg',
        '6/gum.jpg',
        '6/huff.jpg',
        '6/hug.jpg',
        '6/hum.jpg',
        '6/hut.jpg',
        '6/mud.jpg',
        '6/mug.jpg',
        '6/mutt.jpg',
        '6/nun.jpg',
        '6/nut.jpg',
        '6/puff.jpg',
        '6/pup.jpg',
        '6/rub.jpg',
        '6/rug.jpg',
        '6/run.jpg',
        '6/tub.jpg',
        '6/tug.jpg',
      ],
      imageUrlRoot: getImageUrlRoot('/reading-bear'),
      imagePathToWordFn: readingBearImagePathToWordFn,
    }),
  ],

  [
    // c and k
    // ck
    // blends (ending, part 1)
    // blends (ending, part 2)
    // adding s
    'reading-bear-2',
    normalizeWordList({
      paths: [
        '7/Ken.jpg',
        '7/Kim.jpg',
        '7/cab.jpg',
        '7/can.jpg',
        '7/cap.jpg',
        '7/cat.jpg',
        '7/cob.jpg',
        '7/cop.jpg',
        '7/cot.jpg',
        '7/cub.jpg',
        '7/cuff.jpg',
        '7/cup.jpg',
        '7/cut.jpg',
        '7/keg.jpg',
        '7/kid.jpg',
        '7/kin.jpg',
        '7/kiss.jpg',
        '8/Nick.jpg',
        '8/back.jpg',
        '8/buck.jpg',
        '8/deck.jpg',
        '8/dock.jpg',
        '8/duck.jpg',
        '8/kick.jpg',
        '8/lack.jpg',
        '8/lick.jpg',
        '8/lock.jpg',
        '8/luck.jpg',
        '8/neck.jpg',
        '8/pack.jpg',
        '8/pick.jpg',
        '8/rack.jpg',
        '8/rock.jpg',
        '8/sack.jpg',
        '8/sick.jpg',
        '8/sock.jpg',
        '8/suck.jpg',
        '8/tack.jpg',
        '8/tick.jpg',
        '8/tuck.jpg',
        '9/belt.jpg',
        '9/bend.jpg',
        '9/bulb.jpg',
        '9/bump.jpg',
        '9/camp.jpg',
        '9/damp.jpg',
        '9/dump.jpg',
        '9/elf.jpg',
        '9/felt.jpg',
        '9/gift.jpg',
        '9/help.jpg',
        '9/jump.jpg',
        '9/lift.jpg',
        '9/melt.jpg',
        '9/milk.jpg',
        '9/pump.jpg',
        '9/self.jpg',
        '9/send.jpg',
        '10/ask.jpg',
        '10/band.jpg',
        '10/bent.jpg',
        '10/desk.jpg',
        '10/dust.jpg',
        '10/fast.jpg',
        '10/fist.jpg',
        '10/land.jpg',
        '10/last.jpg',
        '10/mist.jpg',
        '10/must.jpg',
        '10/nest.jpg',
        '10/pond.jpg',
        '10/rest.jpg',
        '10/sand.jpg',
        '10/tent.jpg',
        '10/vest.jpg',
        '10/west.jpg',
        '12/asks.jpg',
        '12/bats.jpg',
        '12/beds.jpg',
        '12/cats.jpg',
        '12/cups.jpg',
        '12/cuts.jpg',
        '12/digs.jpg',
        '12/ducks.jpg',
        '12/elms.jpg',
        '12/fins.jpg',
        '12/gifts.jpg',
        '12/helps.jpg',
        '12/hens.jpg',
        '12/hills.jpg',
        '12/hums.jpg',
        '12/jumps.jpg',
        '12/lifts.jpg',
        '12/masks.jpg',
        '12/melts.jpg',
        '12/milks.jpg',
        '12/pants.jpg',
        '12/pigs.jpg',
        '12/pills.jpg',
        '12/pumps.jpg',
        '12/rests.jpg',
        '12/sells.jpg',
        '12/sips.jpg',
        '12/tops.jpg',
        '12/wigs.jpg',
      ],
      imageUrlRoot: getImageUrlRoot('/reading-bear'),
      imagePathToWordFn: readingBearImagePathToWordFn,
    }),
  ],

  [
    // digraphs and x
    // initial blends
    // wh, qu, th
    // ch, tch
    // two syllables
    'reading-bear-3',
    normalizeWordList({
      paths: [
        '13/ash.jpg',
        '13/ax.jpg',
        '13/bangs.jpg',
        '13/bank.jpg',
        '13/cash.jpg',
        '13/dash.jpg',
        '13/dish.jpg',
        '13/dunk.jpg',
        '13/fish.jpg',
        '13/fox.jpg',
        '13/hang.jpg',
        '13/ink.jpg',
        '13/junk.jpg',
        '13/king.jpg',
        '13/long.jpg',
        '13/lungs.jpg',
        '13/mink.jpg',
        '13/mix.jpg',
        '13/ox.jpg',
        '13/pink.jpg',
        '13/rush.jpg',
        '13/sing.jpg',
        '13/six.jpg',
        '13/song.jpg',
        '13/tank.jpg',
        '13/wax.jpg',
        '13/wing.jpg',
        '13/winks.jpg',
        '14/Fran.jpg',
        '14/blink.jpg',
        '14/brat.jpg',
        '14/brush.jpg',
        '14/clap.jpg',
        '14/crust.jpg',
        '14/drink.jpg',
        '14/flock.jpg',
        '14/glad.jpg',
        '14/grip.jpg',
        '14/plump.jpg',
        '14/prank.jpg',
        '14/scamp.jpg',
        '14/scrub.jpg',
        '14/shrug.jpg',
        '14/skid.jpg',
        '14/slack.jpg',
        '14/snap.jpg',
        '14/span.jpg',
        '14/splash.jpg',
        '14/spring.jpg',
        '14/stop.jpg',
        '14/strap.jpg',
        '14/string.jpg',
        '14/swell.jpg',
        '14/trash.jpg',
        '14/twin.jpg',
        '21/Smith.jpg',
        '21/broth.jpg',
        '21/cloth.jpg',
        '21/moth.jpg',
        '21/quack.jpg',
        '21/quick.jpg',
        '21/quill.jpg',
        '21/quilt.jpg',
        '21/quit.jpg',
        '21/quiz.jpg',
        '21/squint.jpg',
        '21/thank.jpg',
        '21/that.jpg',
        '21/then.jpg',
        '21/thick.jpg',
        '21/thin.jpg',
        '21/thing.jpg',
        '21/think.jpg',
        '21/this.jpg',
        '21/thrill.jpg',
        '21/thrush.jpg',
        '21/thump.jpg',
        '21/whack.jpg',
        '21/when.jpg',
        '21/whiff.jpg',
        '21/whisk.jpg',
        '21/with.jpg',
        '22/branch.jpg',
        '22/catch.jpg',
        '22/chat.jpg',
        '22/check.jpg',
        '22/chest.jpg',
        '22/chick.jpg',
        '22/chill.jpg',
        '22/chin.jpg',
        '22/chop.jpg',
        '22/chum.jpg',
        '22/chunk.jpg',
        '22/crunch.jpg',
        '22/crutch.jpg',
        '22/itch.jpg',
        '22/lunch.jpg',
        '22/match.jpg',
        '22/much.jpg',
        '22/patch.jpg',
        '22/pinch.jpg',
        '22/pitch.jpg',
        '22/punch.jpg',
        '22/quench.jpg',
        '22/ranch.jpg',
        '22/rich.jpg',
        '22/sketch.jpg',
        '22/stretch.jpg',
        '22/such.jpg',
        '22/which.jpg',
        '22/witch.jpg',
        '23/be.jpg',
        '23/beach.jpg',
        '23/dear.jpg',
        '23/deep.jpg',
        '23/deer.jpg',
        '23/ear.jpg',
        '23/feet.jpg',
        '23/flea.jpg',
        '23/flee.jpg',
        '23/free.jpg',
        '23/he.jpg',
        '23/me.jpg',
        '23/meat.jpg',
        '23/meet.jpg',
        '23/peach.jpg',
        '23/peak.jpg',
        '23/peek.jpg',
        '23/queen.jpg',
        '23/see.jpg',
        '23/seed.jpg',
        '23/she.jpg',
        '23/speak.jpg',
        '23/steal.jpg',
        '23/steel.jpg',
        '23/street.jpg',
        '23/sweet.jpg',
        '23/tea.jpg',
        '23/teach.jpg',
        '23/we.jpg',
        '23/weak.jpg',
        '23/week.jpg',
        '23/wheel.jpg',
      ],
      imageUrlRoot: getImageUrlRoot('/reading-bear'),
      imagePathToWordFn: readingBearImagePathToWordFn,
    }),
  ],

  [
    // long e
    // oo
    // ar
    // or
    // er, ur, ir
    'reading-bear-4',
    normalizeWordList({
      paths: [
        '24/address.jpg',
        '24/basket.jpg',
        '24/bathtub.jpg',
        '24/biggest.jpg',
        '24/bucket.jpg',
        '24/chicken.jpg',
        '24/chipmunk.jpg',
        '24/crossing.jpg',
        '24/dentist.jpg',
        '24/dustpan.jpg',
        '24/exit.jpg',
        '24/hatrack.jpg',
        '24/kitchen.jpg',
        '24/lemon.jpg',
        '24/longest.jpg',
        '24/napkin.jpg',
        '24/rabbit.jpg',
        '24/robin.jpg',
        '24/shopping.jpg',
        '24/sunset.jpg',
        '24/upset.jpg',
        '24/vanish.jpg',
        '24/wicked.jpg',
        '26/book.jpg',
        '26/broom.jpg',
        '26/cool.jpg',
        '26/drool.jpg',
        '26/food.jpg',
        '26/foot.jpg',
        '26/good.jpg',
        '26/hood.jpg',
        '26/hook.jpg',
        '26/look.jpg',
        '26/moo.jpg',
        '26/moon.jpg',
        '26/noon.jpg',
        '26/roof.jpg',
        '26/room.jpg',
        '26/root.jpg',
        '26/shook.jpg',
        '26/soon.jpg',
        '26/spoon.jpg',
        '26/stood.jpg',
        '26/stool.jpg',
        '26/too.jpg',
        '26/took.jpg',
        '26/tool.jpg',
        '26/tooth.jpg',
        '26/wood.jpg',
        '27/arch.jpg',
        '27/arm.jpg',
        '27/art.jpg',
        '27/bark.jpg',
        '27/car.jpg',
        '27/card.jpg',
        '27/cart.jpg',
        '27/dark.jpg',
        '27/far.jpg',
        '27/harm.jpg',
        '27/lark.jpg',
        '27/ma.jpg',
        '27/march.jpg',
        '27/mark.jpg',
        '27/marsh.jpg',
        '27/pa.jpg',
        '27/park.jpg',
        '27/part.jpg',
        '27/scarf.jpg',
        '27/sharp.jpg',
        '27/smart.jpg',
        '27/spark.jpg',
        '27/star.jpg',
        '27/start.jpg',
        '27/yard.jpg',
        '28/born.jpg',
        '28/cord.jpg',
        '28/cork.jpg',
        '28/corn.jpg',
        '28/for.jpg',
        '28/fork.jpg',
        '28/form.jpg',
        '28/fort.jpg',
        '28/horn.jpg',
        '28/lord.jpg',
        '28/north.jpg',
        '28/or.jpg',
        '28/porch.jpg',
        '28/port.jpg',
        '28/scorch.jpg',
        '28/short.jpg',
        '28/snort.jpg',
        '28/sort.jpg',
        '28/sport.jpg',
        '28/storm.jpg',
        '28/thorn.jpg',
        '28/torch.jpg',
        '28/torn.jpg',
        '28/worn.jpg',
        '29/birch.jpg',
        '29/bird.jpg',
        '29/burn.jpg',
        '29/burst.jpg',
        '29/chirp.jpg',
        '29/church.jpg',
        '29/curb.jpg',
        '29/curl.jpg',
        '29/dirt.jpg',
        '29/fern.jpg',
        '29/fir.jpg',
        '29/firm.jpg',
        '29/first.jpg',
        '29/fur.jpg',
        '29/girl.jpg',
        '29/her.jpg',
        '29/herd.jpg',
        '29/hers.jpg',
        '29/hurt.jpg',
        '29/purr.jpg',
        '29/shirt.jpg',
        '29/sir.jpg',
        '29/skirt.jpg',
        '29/squirm.jpg',
        '29/squirt.jpg',
        '29/stir.jpg',
        '29/surf.jpg',
        '29/third.jpg',
        '29/turn.jpg',
      ],
      imageUrlRoot: getImageUrlRoot('/reading-bear'),
      imagePathToWordFn: readingBearImagePathToWordFn,
    }),
  ],

  [
    // oy, oi
    // ow, ou
    // aw, au, al
    // ai
    // ay, air
    'reading-bear-5',
    normalizeWordList({
      paths: [
        '31/boil.jpg',
        '31/boy.jpg',
        '31/broil.jpg',
        '31/coil.jpg',
        '31/coin.jpg',
        '31/coy.jpg',
        '31/foil.jpg',
        '31/hoist.jpg',
        '31/join.jpg',
        '31/joint.jpg',
        '31/joy.jpg',
        '31/moist.jpg',
        '31/oil.jpg',
        '31/point.jpg',
        '31/roy.jpg',
        '31/soil.jpg',
        '31/spoil.jpg',
        '31/toil.jpg',
        '31/toy.jpg',
        '40/bound.jpg',
        '40/brown.jpg',
        '40/cloud.jpg',
        '40/clown.jpg',
        '40/couch.jpg',
        '40/count.jpg',
        '40/cow.jpg',
        '40/crown.jpg',
        '40/down.jpg',
        '40/flour.jpg',
        '40/found.jpg',
        '40/frown.jpg',
        '40/grouch.jpg',
        '40/ground.jpg',
        '40/growl.jpg',
        '40/how.jpg',
        '40/howl.jpg',
        '40/loud.jpg',
        '40/mouth.jpg',
        '40/now.jpg',
        '40/our.jpg',
        '40/out.jpg',
        '40/ow.jpg',
        '40/owl.jpg',
        '40/pound.jpg',
        '40/pout.jpg',
        '40/proud.jpg',
        '40/round.jpg',
        '40/shout.jpg',
        '40/snout.jpg',
        '40/sound.jpg',
        '40/sour.jpg',
        '40/south.jpg',
        '40/sprout.jpg',
        '40/town.jpg',
        '41/all.jpg',
        '41/bald.jpg',
        '41/ball.jpg',
        '41/chalk.jpg',
        '41/claw.jpg',
        '41/dawn.jpg',
        '41/fall.jpg',
        '41/fault.jpg',
        '41/hall.jpg',
        '41/halt.jpg',
        '41/haunt.jpg',
        '41/hawk.jpg',
        '41/jaw.jpg',
        '41/launch.jpg',
        '41/law.jpg',
        '41/lawn.jpg',
        '41/raw.jpg',
        '41/salt.jpg',
        '41/saw.jpg',
        '41/small.jpg',
        '41/stalk.jpg',
        '41/straw.jpg',
        '41/talk.jpg',
        '41/tall.jpg',
        '41/walk.jpg',
        '41/yawn.jpg',
        '42/aim.jpg',
        '42/bait.jpg',
        '42/brain.jpg',
        '42/drain.jpg',
        '42/grain.jpg',
        '42/hail.jpg',
        '42/mail.jpg',
        '42/main.jpg',
        '42/paid.jpg',
        '42/pail.jpg',
        '42/pain.jpg',
        '42/paint.jpg',
        '42/plain.jpg',
        '42/quail.jpg',
        '42/rail.jpg',
        '42/rain.jpg',
        '42/sail.jpg',
        '42/saint.jpg',
        '42/snail.jpg',
        '42/stain.jpg',
        '42/tail.jpg',
        '42/trail.jpg',
        '42/train.jpg',
        '42/wait.jpg',
        '43/air.jpg',
        '43/bay.jpg',
        '43/chair.jpg',
        '43/day.jpg',
        '43/fair.jpg',
        '43/gray.jpg',
        '43/hair.jpg',
        '43/hay.jpg',
        '43/lay.jpg',
        '43/may.jpg',
        '43/pair.jpg',
        '43/pay.jpg',
        '43/play.jpg',
        '43/pray.jpg',
        '43/ray.jpg',
        '43/say.jpg',
        '43/spray.jpg',
        '43/stair.jpg',
        '43/stay.jpg',
        '43/way.jpg',
      ],
      imageUrlRoot: getImageUrlRoot('/reading-bear'),
      imagePathToWordFn: readingBearImagePathToWordFn,
    }),
  ],

  [
    // y, ie, ind, ild
    // o, oa, oe
    // old, olt, ow
    // ew, ue
    // 2 & 3 syllables
    'reading-bear-6',
    normalizeWordList({
      paths: [
        '44/by.jpg',
        '44/child.jpg',
        '44/cry.jpg',
        '44/dried.jpg',
        '44/dry.jpg',
        '44/find.jpg',
        '44/flies.jpg',
        '44/fry.jpg',
        '44/kind.jpg',
        '44/lie.jpg',
        '44/mild.jpg',
        '44/mind.jpg',
        '44/my.jpg',
        '44/pie.jpg',
        '44/rind.jpg',
        '44/rye.jpg',
        '44/shy.jpg',
        '44/sky.jpg',
        '44/tie.jpg',
        '44/tied.jpg',
        '44/tried.jpg',
        '44/why.jpg',
        '44/wild.jpg',
        '64/boat.jpg',
        '64/coach.jpg',
        '64/coast.jpg',
        '64/coat.jpg',
        '64/doe.jpg',
        '64/float.jpg',
        '64/go.jpg',
        '64/goal.jpg',
        '64/goat.jpg',
        '64/goes.jpg',
        '64/hoe.jpg',
        '64/joe.jpg',
        '64/load.jpg',
        '64/no.jpg',
        '64/oak.jpg',
        '64/oar.jpg',
        '64/oats.jpg',
        '64/road.jpg',
        '64/roar.jpg',
        '64/so.jpg',
        '64/throat.jpg',
        '64/toad.jpg',
        '64/toast.jpg',
        '64/toe.jpg',
        '65/blow.jpg',
        '65/bolt.jpg',
        '65/bowl.jpg',
        '65/cold.jpg',
        '65/colt.jpg',
        '65/fold.jpg',
        '65/glow.jpg',
        '65/gold.jpg',
        '65/grow.jpg',
        '65/hold.jpg',
        '65/low.jpg',
        '65/mow.jpg',
        '65/old.jpg',
        '65/roll.jpg',
        '65/row.jpg',
        '65/scold.jpg',
        '65/show.jpg',
        '65/slow.jpg',
        '65/snow.jpg',
        '65/sold.jpg',
        '65/stroll.jpg',
        '65/throw.jpg',
        '65/told.jpg',
        '66/Sue.jpg',
        '66/blew.jpg',
        '66/blue.jpg',
        '66/chew.jpg',
        '66/crew.jpg',
        '66/dew.jpg',
        '66/drew.jpg',
        '66/glue.jpg',
        '66/new.jpg',
        '66/news.jpg',
        '66/screw.jpg',
        '66/stew.jpg',
        '66/threw.jpg',
        '66/true.jpg',
        '67/always.jpg',
        '67/august.jpg',
        '67/banjo.jpg',
        '67/burglar.jpg',
        '67/butterfly.jpg',
        '67/cartoon.jpg',
        '67/columbus.jpg',
        '67/flowers.jpg',
        '67/lantern.jpg',
        '67/mailbox.jpg',
        '67/mustard.jpg',
        '67/november.jpg',
        '67/numbers.jpg',
        '67/oatmeal.jpg',
        '67/punishment.jpg',
        '67/railroad.jpg',
        '67/rainbow.jpg',
        '67/return.jpg',
        '67/saturday.jpg',
        '67/september.jpg',
        '67/shadow.jpg',
        '67/snowball.jpg',
        '67/sunday.jpg',
        '67/thursday.jpg',
        '67/tower.jpg',
        '67/tuesday.jpg',
        '67/understand.jpg',
      ],
      imageUrlRoot: getImageUrlRoot('/reading-bear'),
      imagePathToWordFn: readingBearImagePathToWordFn,
    }),
  ],

  [
    // a_e and e_e
    // i_e
    // o_e
    // u_e
    // ing
    'reading-bear-7',
    normalizeWordList({
      paths: [
        '68/ape.jpg',
        '68/ate.jpg',
        '68/bake.jpg',
        '68/cake.jpg',
        '68/came.jpg',
        '68/cape.jpg',
        '68/crete.jpg',
        '68/eve.jpg',
        '68/fade.jpg',
        '68/gene.jpg',
        '68/glade.jpg',
        '68/haste.jpg',
        '68/hate.jpg',
        '68/here.jpg',
        '68/lake.jpg',
        '68/made.jpg',
        '68/paste.jpg',
        '68/plane.jpg',
        '68/plate.jpg',
        '68/same.jpg',
        '68/shake.jpg',
        '68/snake.jpg',
        '68/square.jpg',
        '68/steve.jpg',
        '68/tape.jpg',
        '68/trade.jpg',
        '68/wave.jpg',
        '69/bite.jpg',
        '69/chime.jpg',
        '69/dime.jpg',
        '69/file.jpg',
        '69/fire.jpg',
        '69/hire.jpg',
        '69/kite.jpg',
        '69/like.jpg',
        '69/mile.jpg',
        '69/mine.jpg',
        '69/pine.jpg',
        '69/ride.jpg',
        '69/ripe.jpg',
        '69/shine.jpg',
        '69/site.jpg',
        '69/spike.jpg',
        '69/spine.jpg',
        '69/tide.jpg',
        '69/time.jpg',
        '69/tire.jpg',
        '69/white.jpg',
        '69/wine.jpg',
        '71/bone.jpg',
        '71/broke.jpg',
        '71/core.jpg',
        '71/dome.jpg',
        '71/drove.jpg',
        '71/froze.jpg',
        '71/globe.jpg',
        '71/hole.jpg',
        '71/hope.jpg',
        '71/joke.jpg',
        '71/mole.jpg',
        '71/more.jpg',
        '71/note.jpg',
        '71/poke.jpg',
        '71/robe.jpg',
        '71/rode.jpg',
        '71/rome.jpg',
        '71/rope.jpg',
        '71/score.jpg',
        '71/sore.jpg',
        '71/spoke.jpg',
        '71/stone.jpg',
        '71/stove.jpg',
        '71/throne.jpg',
        '71/vote.jpg',
        '72/brute.jpg',
        '72/cube.jpg',
        '72/cure.jpg',
        '72/cute.jpg',
        '72/duke.jpg',
        '72/fluke.jpg',
        '72/flute.jpg',
        '72/june.jpg',
        '72/lute.jpg',
        '72/mule.jpg',
        '72/prune.jpg',
        '72/pure.jpg',
        '72/rude.jpg',
        '72/rule.jpg',
        '72/tube.jpg',
        '72/tune.jpg',
        '73/budding.jpg',
        '73/buzzing.jpg',
        '73/filing.jpg',
        '73/grabbing.jpg',
        '73/hoping.jpg',
        '73/letting.jpg',
        '73/licking.jpg',
        '73/naming.jpg',
        '73/purring.jpg',
        '73/rubbing.jpg',
        '73/sagging.jpg',
        '73/scrapping.jpg',
        '73/setting.jpg',
        '73/sharing.jpg',
        '73/shipping.jpg',
        '73/shopping.jpg',
        '73/skipping.jpg',
        '73/sledding.jpg',
        '73/slipping.jpg',
        '73/spelling.jpg',
        '73/spinning.jpg',
        '73/stirring.jpg',
        '73/tapping.jpg',
        '73/whipping.jpg',
      ],
      imageUrlRoot: getImageUrlRoot('/reading-bear'),
      imagePathToWordFn: readingBearImagePathToWordFn,
    }),
  ],

  [
    // y and more
    // ed
    // er, le
    // ce, ci, cy
    // ge, dge, etc
    'reading-bear-8',
    normalizeWordList({
      paths: [
        '74/babies.jpg',
        '74/baby.jpg',
        '74/berries.jpg',
        '74/berry.jpg',
        '74/carried.jpg',
        '74/chillier.jpg',
        '74/daddy.jpg',
        '74/daily.jpg',
        '74/dirtiest.jpg',
        '74/funnily.jpg',
        '74/gladly.jpg',
        '74/happily.jpg',
        '74/hurried.jpg',
        '74/ivy.jpg',
        '74/navy.jpg',
        '74/party.jpg',
        '74/ponies.jpg',
        '74/pony.jpg',
        '74/puppies.jpg',
        '74/puppy.jpg',
        '74/roomier.jpg',
        '74/sadly.jpg',
        '74/shady.jpg',
        '74/silliest.jpg',
        '74/sleepily.jpg',
        '74/sunniest.jpg',
        '74/thirsty.jpg',
        '74/twenty.jpg',
        '74/uglier.jpg',
        '74/ugly.jpg',
        '75/added.jpg',
        '75/aimed.jpg',
        '75/baked.jpg',
        '75/boiled.jpg',
        '75/counted.jpg',
        '75/cracked.jpg',
        '75/crashed.jpg',
        '75/crawled.jpg',
        '75/dropped.jpg',
        '75/filled.jpg',
        '75/fished.jpg',
        '75/fixed.jpg',
        '75/followed.jpg',
        '75/hissed.jpg',
        '75/kissed.jpg',
        '75/needed.jpg',
        '75/parked.jpg',
        '75/played.jpg',
        '75/pointed.jpg',
        '75/rested.jpg',
        '75/robbed.jpg',
        '75/sailed.jpg',
        '75/scratched.jpg',
        '75/seated.jpg',
        '75/shouted.jpg',
        '75/skipped.jpg',
        '75/sneezed.jpg',
        '75/stacked.jpg',
        '75/stitched.jpg',
        '75/wicked.jpg',
        '75/wiped.jpg',
        '76/Bible.jpg',
        '76/angle.jpg',
        '76/better.jpg',
        '76/bigger.jpg',
        '76/bottle.jpg',
        '76/cattle.jpg',
        '76/dinner.jpg',
        '76/drizzle.jpg',
        '76/fiddle.jpg',
        '76/guzzle.jpg',
        '76/humble.jpg',
        '76/jingle.jpg',
        '76/juggler.jpg',
        '76/jungle.jpg',
        '76/kettle.jpg',
        '76/ladder.jpg',
        '76/needle.jpg',
        '76/pepper.jpg',
        '76/puzzle.jpg',
        '76/riddle.jpg',
        '76/rocker.jpg',
        '76/rubber.jpg',
        '76/saddle.jpg',
        '76/simple.jpg',
        '76/slipper.jpg',
        '76/snuggle.jpg',
        '76/supper.jpg',
        '76/table.jpg',
        '76/tangle.jpg',
        '76/trigger.jpg',
        '77/France.jpg',
        '77/Francis.jpg',
        '77/Nancy.jpg',
        '77/ace.jpg',
        '77/cell.jpg',
        '77/cent.jpg',
        '77/center.jpg',
        '77/choice.jpg',
        '77/cigar.jpg',
        '77/cinders.jpg',
        '77/circus.jpg',
        '77/city.jpg',
        '77/concert.jpg',
        '77/danced.jpg',
        '77/fancy.jpg',
        '77/fencing.jpg',
        '77/icing.jpg',
        '77/lace.jpg',
        '77/mice.jpg',
        '77/officer.jpg',
        '77/ounce.jpg',
        '77/pace.jpg',
        '77/peace.jpg',
        '77/pencil.jpg',
        '77/place.jpg',
        '77/prince.jpg',
        '77/since.jpg',
        '77/slice.jpg',
        '77/space.jpg',
        '77/spruce.jpg',
        '77/traced.jpg',
        '78/George.jpg',
        '78/age.jpg',
        '78/badge.jpg',
        '78/bridge.jpg',
        '78/cabbage.jpg',
        '78/change.jpg',
        '78/changing.jpg',
        '78/danger.jpg',
        '78/dodge.jpg',
        '78/edge.jpg',
        '78/engineer.jpg',
        '78/gem.jpg',
        '78/gentleman.jpg',
        '78/germs.jpg',
        '78/ginger.jpg',
        '78/gym.jpg',
        '78/gypsy.jpg',
        '78/hedge.jpg',
        '78/hinges.jpg',
        '78/huge.jpg',
        '78/magic.jpg',
        '78/nudged.jpg',
        '78/package.jpg',
        '78/page.jpg',
        '78/passage.jpg',
        '78/pigeon.jpg',
        '78/rage.jpg',
        '78/stage.jpg',
        '78/strangers.jpg',
        '78/urgent.jpg',
      ],
      imageUrlRoot: getImageUrlRoot('/reading-bear'),
      imagePathToWordFn: readingBearImagePathToWordFn,
    }),
  ],

  [
    // the, se, etc
    // silent b, k, t, w
    // silent gh, h, l
    // ph, gh
    // ea and ear
    'reading-bear-9',
    normalizeWordList({
      paths: [
        '79/amusing.jpg',
        '79/bathe.jpg',
        '79/because.jpg',
        '79/bother.jpg',
        '79/breathe.jpg',
        '79/brother.jpg',
        '79/cheese.jpg',
        '79/close.jpg',
        '79/daisy.jpg',
        '79/father.jpg',
        '79/gather.jpg',
        '79/hose.jpg',
        '79/mother.jpg',
        '79/noise.jpg',
        '79/noisy.jpg',
        '79/other.jpg',
        '79/pause.jpg',
        '79/please.jpg',
        '79/pleased.jpg',
        '79/raise.jpg',
        '79/rise.jpg',
        '79/rose.jpg',
        '79/rosy.jpg',
        '79/these.jpg',
        '79/those.jpg',
        '79/whether.jpg',
        '79/wise.jpg',
        '79/wither.jpg',
        '80/castle.jpg',
        '80/crumb.jpg',
        '80/doubt.jpg',
        '80/gnat.jpg',
        '80/gnaw.jpg',
        '80/gnome.jpg',
        '80/gnu.jpg',
        '80/hustle.jpg',
        '80/knee.jpg',
        '80/knelt.jpg',
        '80/knife.jpg',
        '80/knit.jpg',
        '80/knock.jpg',
        '80/knot.jpg',
        '80/know.jpg',
        '80/limb.jpg',
        '80/listen.jpg',
        '80/numb.jpg',
        '80/plumber.jpg',
        '80/thistle.jpg',
        '80/thumb.jpg',
        '80/whistle.jpg',
        '80/wrap.jpg',
        '80/wreath.jpg',
        '80/wreck.jpg',
        '80/wrestle.jpg',
        '80/wring.jpg',
        '80/wrist.jpg',
        '80/write.jpg',
        '80/wrong.jpg',
        '81/John.jpg',
        '81/bought.jpg',
        '81/bright.jpg',
        '81/brought.jpg',
        '81/calf.jpg',
        '81/caught.jpg',
        '81/daughter.jpg',
        '81/eighty.jpg',
        '81/freight.jpg',
        '81/fright.jpg',
        '81/half.jpg',
        '81/high.jpg',
        '81/honest.jpg',
        '81/honor.jpg',
        '81/hour.jpg',
        '81/knight.jpg',
        '81/mighty.jpg',
        '81/naught.jpg',
        '81/night.jpg',
        '81/ought.jpg',
        '81/right.jpg',
        '81/sigh.jpg',
        '81/sleigh.jpg',
        '81/straight.jpg',
        '81/taught.jpg',
        '81/thought.jpg',
        '81/tight.jpg',
        '81/weigh.jpg',
        '81/weight.jpg',
        '82/Sophie.jpg',
        '82/alphabet.jpg',
        '82/autograph.jpg',
        '82/cough.jpg',
        '82/elephant.jpg',
        '82/enough.jpg',
        '82/laugh.jpg',
        '82/pharmacy.jpg',
        '82/phone.jpg',
        '82/photo.jpg',
        '82/rough.jpg',
        '82/tough.jpg',
        '82/trophy.jpg',
        '82/trough.jpg',
        '83/bear.jpg',
        '83/bread.jpg',
        '83/break.jpg',
        '83/breakfast.jpg',
        '83/breath.jpg',
        '83/dead.jpg',
        '83/earth.jpg',
        '83/feather.jpg',
        '83/great.jpg',
        '83/head.jpg',
        '83/health.jpg',
        '83/heard.jpg',
        '83/heaven.jpg',
        '83/heavy.jpg',
        '83/instead.jpg',
        '83/learn.jpg',
        '83/pear.jpg',
        '83/pearl.jpg',
        '83/ready.jpg',
        '83/search.jpg',
        '83/spread.jpg',
        '83/steak.jpg',
        '83/sweat.jpg',
        '83/sweater.jpg',
        '83/thread.jpg',
        '83/tread.jpg',
        '83/wealthy.jpg',
        '83/wear.jpg',
        '83/weather.jpg',
      ],
      imageUrlRoot: getImageUrlRoot('/reading-bear'),
      imagePathToWordFn: readingBearImagePathToWordFn,
    }),
  ],

  [
    // ie, ui, u
    // wor, wa, ou
    // si, su, ci, ti, tu
    // ive, or, ence
    // 3 & 4 syllables
    'reading-bear-10',
    normalizeWordList({
      paths: [
        '84/Charlie.jpg',
        '84/believe.jpg',
        '84/brief.jpg',
        '84/brownie.jpg',
        '84/bush.jpg',
        '84/chief.jpg',
        '84/field.jpg',
        '84/fiend.jpg',
        '84/fierce.jpg',
        '84/fruit.jpg',
        '84/fruitful.jpg',
        '84/juice.jpg',
        '84/nuisance.jpg',
        '84/piece.jpg',
        '84/pier.jpg',
        '84/pierce.jpg',
        '84/priest.jpg',
        '84/pudding.jpg',
        '84/push.jpg',
        '84/pussycat.jpg',
        '84/put.jpg',
        '84/shrieked.jpg',
        '84/siege.jpg',
        '84/suit.jpg',
        '84/thief.jpg',
        '84/yield.jpg',
        '85/famous.jpg',
        '85/gorgeous.jpg',
        '85/marvelous.jpg',
        '85/nervous.jpg',
        '85/quality.jpg',
        '85/quantity.jpg',
        '85/quark.jpg',
        '85/swallow.jpg',
        '85/swamp.jpg',
        '85/swan.jpg',
        '85/touchy.jpg',
        '85/trouble.jpg',
        '85/waffles.jpg',
        '85/wall.jpg',
        '85/wander.jpg',
        '85/want.jpg',
        '85/wash.jpg',
        '85/watch.jpg',
        '85/water.jpg',
        '85/word.jpg',
        '85/work.jpg',
        '85/world.jpg',
        '85/worm.jpg',
        '85/worry.jpg',
        '85/worship.jpg',
        '85/worth.jpg',
        '85/young.jpg',
        '86/action.jpg',
        '86/addition.jpg',
        '86/attention.jpg',
        '86/cautious.jpg',
        '86/delicious.jpg',
        '86/expression.jpg',
        '86/fiction.jpg',
        '86/fraction.jpg',
        '86/gracious.jpg',
        '86/mansion.jpg',
        '86/measure.jpg',
        '86/mixture.jpg',
        '86/nation.jpg',
        '86/nature.jpg',
        '86/occasion.jpg',
        '86/patient.jpg',
        '86/permission.jpg',
        '86/picture.jpg',
        '86/pleasure.jpg',
        '86/precious.jpg',
        '86/social.jpg',
        '86/station.jpg',
        '86/suspicious.jpg',
        '86/treasure.jpg',
        '86/usual.jpg',
        '86/vacation.jpg',
        '86/vicious.jpg',
        '87/active.jpg',
        '87/appearance.jpg',
        '87/become.jpg',
        '87/come.jpg',
        '87/conductor.jpg',
        '87/confidence.jpg',
        '87/detective.jpg',
        '87/doctor.jpg',
        '87/expensive.jpg',
        '87/favor.jpg',
        '87/fence.jpg',
        '87/give.jpg',
        '87/handsome.jpg',
        '87/importance.jpg',
        '87/income.jpg',
        '87/instructor.jpg',
        '87/motor.jpg',
        '87/native.jpg',
        '87/passive.jpg',
        '87/performance.jpg',
        '87/razor.jpg',
        '87/residence.jpg',
        '87/sailor.jpg',
        '87/some.jpg',
        '87/somehow.jpg',
        '87/something.jpg',
        '87/sometimes.jpg',
        '87/visitor.jpg',
        '87/welcome.jpg',
        '88/Cinderella.jpg',
        '88/Washington.jpg',
        '88/accident.jpg',
        '88/afternoon.jpg',
        '88/beginning.jpg',
        '88/blueberries.jpg',
        '88/committee.jpg',
        '88/conclusion.jpg',
        '88/correction.jpg',
        '88/dictionary.jpg',
        '88/emergency.jpg',
        '88/emperor.jpg',
        '88/exchanging.jpg',
        '88/gorilla.jpg',
        '88/impatience.jpg',
        '88/merchandise.jpg',
        '88/musician.jpg',
        '88/passengers.jpg',
        '88/peevishly.jpg',
        '88/permanent.jpg',
        '88/surrounded.jpg',
        '88/thunderstorm.jpg',
        '88/transportation.jpg',
        '88/underneath.jpg',
        '88/understand.jpg',
      ],
      imageUrlRoot: getImageUrlRoot('/reading-bear'),
      imagePathToWordFn: readingBearImagePathToWordFn,
    }),
  ],
]);
